.HighlightCard {
    &.MuiPaper-root{
        background-color: rgb(212,225,244, .8);
    }
    width: fit-content;
    height: auto;
    margin: 5px;
    padding: 5px;
    background-color: rgb(212,225,244, .8);
}

.EndorsementCard {
    width: 100%;
    height: auto;
    margin: 5px;
    padding: 5px;
}