.App {
  width: 100%;
  height: 100vh;
  background-color: #344f6b;
  background-image: url("../public/Stars-and-Sea-Watercolor.jpg");
  background-position: center;
  background-size: cover;
  font-family: 'monospace';
  overflow: auto;
}

.PaperLayout {
  &.MuiPaper-root {
    width: auto;
    height: auto;
    margin: 24px;
    padding: 24px;
    background-color: rgb(212,225,244, .95);
  }
}
