.ExperienceCard {
    margin-bottom: 10px;
}

.SkillDisplayCard {
    &.MuiPaper-root{
        background-color: rgb(212,225,244, .8);
    }
    width: fit-content;
    height: auto;
    margin: 5px;
    padding: 5px;
    color: rgb(212,225,244, .8);
}

.LanguageIcon {
    margin-right: 4px;
}