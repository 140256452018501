.ProfilePicture {
    max-width: 95%;
    margin: auto;
}

.MainPageContainer {
    align-self: center;
    justify-content: center;
}

.carouselItem {
    width: auto;
}